// Imports
var ___CSS_LOADER_API_IMPORT___ = require("C:\\Users\\Vision\\AppData\\Roaming\\nvm\\v16.13.1\\node_modules\\expo-cli\\node_modules\\css-loader\\dist\\runtime\\api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("C:\\Users\\Vision\\AppData\\Roaming\\nvm\\v16.13.1\\node_modules\\expo-cli\\node_modules\\css-loader\\dist\\runtime\\getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./img/team-shape.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".team .member .member-img:after{position:absolute;content:\"\";left:0;bottom:0;height:100%;width:100%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center bottom;background-size:contain;z-index:1}", ""]);
// Exports
module.exports = exports;
